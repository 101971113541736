import React from 'react';
import styled from "styled-components";

const Btn = styled.a`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 60px;
  text-align: center;
  color: #FBFDFE;
  text-shadow: 0px 2px 2px rgba(12, 17, 27, 0.2);
  background: radial-gradient(63.11% 63.11% at 31.97% 19.67%, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%), #38C558;
  border-radius: 8px;
  width: 200px;
  height: 60px;
  position: absolute;
  top: 420px;
  left: calc(50% - 100px);
  text-transform: uppercase;
  display: block;
  text-decoration: none;
  user-select: none;
  transition: top ease 0.2s;
  z-index: 22;

  &:hover {
    top: 417px;
  }

  &:after {
    content: "";
    display: block;
    width: 200px;
    height: 60px;
    position: absolute;
    top: 0;
    left: 0;
    background: radial-gradient(65.81% 80.33% at 31.97% 19.67%, rgba(255, 255, 255, 0.476) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%), #45C763;
    opacity: 0.3;
    filter: blur(40px);
    border-radius: 8px;
  }
`;

const SingleButton = ({ promoUrl, buttonText }) => (
  <Btn href={promoUrl}>{buttonText}</Btn>
);

export default SingleButton;
