import React, { useEffect } from 'react';
import { addStag } from '../utils/utils';
import SingleButton from '../components/SingleButton';
import HowTo from '../components/HowTo/HowTo';
import ContentVariantA from '../components/ContentVariantA';
import HeaderEllipse from '../components/HeaderEllipse/HeaderEllipse';
import FixedHeader from '../components/FixedHeader/FixedHeader';
import styled, { createGlobalStyle } from "styled-components";
import FooterVariantA from '../components/FooterVariantA/FooterVariantA';
import Helmet from 'react-helmet';
import reset from 'styled-reset';
import AOS from 'aos';
import { isSafari, isIOS } from "react-device-detect";
import "../../node_modules/aos/dist/aos.css";

const GlobalStyle = createGlobalStyle`
  ${reset}
  
  body {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    background: #0C111B;
    color: #838FA3;
    overflow-x: hidden;
  }
  
  ::-webkit-scrollbar {
    width: 15px;
  }

  ::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: #303D58;
  }

  ::-webkit-scrollbar-track {
    background: #0C111B;
  }
  
  img {
    user-drag: none;
    user-select: none;
  }

  @keyframes textchange {
    0%,10%,90%,100% { margin-top: -24px; }
    23%,33.33%,43% { margin-top: -114px; }
    56%,66.66%,76% { margin-top: -204px; }
    76.1% { margin-top: 24px }
  }
`;

const ContentInner = styled.div`
  padding-top: 150px;
  padding-bottom: 150px;
`;

const LP1 = ({ pageContext }) => {
  const {
    headerButtonText,
    promoUrl,
    title,
    subTitle,
    titleCaption = [],
    company = "Sportbet.one",
    footerText,
    copyright,
    termsAndConditionsTitle,
    termsApplyButtonText,
    showTermsAndConditions,
    termsAndConditions,
    buttonText,
    sectionsTitle,
    section1ButtonText,
    section1Description,
    section1Title,
    section2Description,
    section2Title,
    section3ButtonText,
    section3Title,
    section3Description,
    image1,
    image2,
    image3,
    image4,
    image5,
  } = pageContext;

  useEffect(() => {
    if (typeof document === "undefined") return;
    const linkClick = (e) => {
      const href = e.currentTarget.href;
      if (href.includes("promo=")) {
        e.preventDefault();
        window.location.href = addStag(href);
      }
    };

    document.querySelectorAll("a").forEach(x => x.addEventListener("click", linkClick));
    return () => {
      document.querySelectorAll("a").forEach(x => x.removeEventListener("click", linkClick));
    };
  }, []);

  useEffect(() => {
    AOS.init({
      once: true
    });
    AOS.refresh();
  }, []);

  return (
    <>
      <Helmet>
        <title>Sportbet.one</title>
      </Helmet>
      <GlobalStyle />
      <FixedHeader
        promoUrl={promoUrl}
        company={company}
        headerButtonText={headerButtonText}
      />
      <ContentVariantA>
        <HeaderEllipse
          title={title}
          subTitle={subTitle}
          titleCaption={titleCaption}
          image1={image1}
          image2={image2}
          image3={image3}
          image4={image4}
          image5={image5}
          notSafari={!isSafari && !isIOS}
        />
        <ContentInner>
          <SingleButton buttonText={buttonText} promoUrl={promoUrl} />
          <HowTo
            sectionsTitle={sectionsTitle}
            section1ButtonText={section1ButtonText}
            section1Description={section1Description}
            section1Title={section1Title}
            section2Description={section2Description}
            section2Title={section2Title}
            section3ButtonText={section3ButtonText}
            section3Title={section3Title}
            section3Description={section3Description}
          />
        </ContentInner>
      </ContentVariantA>
      <FooterVariantA
        showTermsAndConditions={showTermsAndConditions}
        termsAndConditionsTitle={termsAndConditionsTitle}
        company={company}
        footerText={footerText}
        copyright={copyright}
        termsAndConditions={termsAndConditions}
        termsApplyButtonText={termsApplyButtonText}
      />
    </>
  );
};

export default LP1;
